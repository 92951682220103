import one from "./one.png";
import two from "./two.png";
import three from "./three.png";
import four from "./four.png";
import five from "./five.png";
import six from "./six.png";
import seven from "./seven.png";
import eight from "./eight.png";
import nine from "./nine.png";
import ten from "./ten.png";
import eleven from "./eleven.png";
import twelve from "./twelve.png";
import thirteen from "./thirteen.png";
import fourteen from "./fourteen.png";
import fifteen from "./fifteen.png";
import sixteen from "./sixteen.png";
import seventeen from "./seventeen.png";

const teamImg = {
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
  eleven,
  twelve,
  thirteen,
  fourteen,
  fifteen,
  sixteen,
  seventeen,
};

export default teamImg;
